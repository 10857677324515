@import url("https://fonts.googleapis.com/css?family=Fira+Code|Spectral&display=swap");

:root {
  --main-purple: rgb(167, 77, 229);
  --main-red: rgb(255, 102, 64);
  --main-orange: rgb(253, 193, 82);
  --main-blue: rgb(99, 200, 255);
  --main-darkblue: rgb(2, 117, 216); /* same as bootstrap primary */
  --main-green: rgb(140, 255, 140);
  --main-yellow: rgb(236, 236, 127);
  --main-lightgray: rgb(247, 247, 247);
  --main-mediumgray: rgb(222, 222, 222);
  --main-darkgray: rgb(131, 131, 131);
}

body {
  margin: 0;
  font-family: "Spectral", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overscroll-behavior-y: none;
}

#root {
  min-height: 105vh;
}

code {
  font-family: "Fira Code", monospace;
}

h1 {
  margin: 0;
  font-size: 2.5em;
}

.gray-sample {
  height: 15px;
  width: 15px;
  background-color: var(--main-mediumgray);
  display: inline-block;
  vertical-align: sub;
}
.green-sample {
  height: 15px;
  width: 15px;
  background-color: var(--main-green);
  display: inline-block;
  vertical-align: sub;
}
.yellow-sample {
  height: 15px;
  width: 15px;
  background-color: var(--main-yellow);
  display: inline-block;
  vertical-align: sub;
}
.orange-sample {
  height: 15px;
  width: 15px;
  background-color: var(--main-orange);
  display: inline-block;
  vertical-align: sub;
}
.red-sample {
  height: 15px;
  width: 15px;
  background-color: var(--main-red);
  display: inline-block;
  vertical-align: sub;
}
.green-striped-sample {
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: sub;
  background: repeating-linear-gradient(
    135deg,
    white,
    white 1px,
    var(--main-green) 2px,
    var(--main-green) 4px
  );
}
.yellow-striped-sample {
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: sub;
  background: repeating-linear-gradient(
    135deg,
    white,
    white 1px,
    var(--main-yellow) 2px,
    var(--main-yellow) 4px
  );
}
.orange-striped-sample {
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: sub;
  background: repeating-linear-gradient(
    135deg,
    white,
    white 1px,
    var(--main-orange) 2px,
    var(--main-orange) 4px
  );
}
.red-striped-sample {
  height: 15px;
  width: 15px;
  display: inline-block;
  vertical-align: sub;
  background: repeating-linear-gradient(
    135deg,
    white,
    white 1px,
    var(--main-red) 2px,
    var(--main-red) 4px
  );
}
.dot-sample {
  height: 4px;
  width: 4px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  vertical-align: baseline;
}
ul.legend {
  list-style-type: none;
  padding-left: 0;
}

.list-inline-item {
  margin: 0 20 !important;
}